// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../resources/logo-white.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mobileDrop[data-v-4cf23697]{background:var(--color-bg-light);transition:all .3s ease;overflow:none}.logo[data-v-4cf23697]{justify-self:start;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;background-size:contain;width:20rem;height:3rem;margin-right:2rem}.menuBtn[data-v-4cf23697]{height:1rem;line-height:1rem;padding:.25rem .5rem;margin:.25rem;color:var(--color-logo-light);font-weight:normal;text-decoration:none;font-size:1.5rem}.menuBtn[data-v-4cf23697]:hover{color:var(--color-text-primary)}.header-wrapper[data-v-4cf23697]{color:var(--color-logo-light);background:var(--color-bg-dark);background:linear-gradient(90deg, var(--color-bg-dark) 0%, var(--color-bg-light) 75%),var(--color-bg-dark) 100%;width:100%;display:flex;justify-content:space-between;border-bottom:.2rem solid;border-color:var(--color-bg-light)}.header-wrapper .pi-bars[data-v-4cf23697]{color:var(--color-logo-light)}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
