// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/resources/Azonix.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Azonix;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\")}:root{--color-bg: #f3faff;--color-bg-dark: #1d2731;--color-bg-light: #0b3c5d;--color-section-dark: #3a4856;--color-section-light: #f3faff;--color-logo-light: #f3faff;--color-detail-light: #328cc1;--color-detail-dark: #1d2731;--color-text-primary: #f6f6f6;--color-text-secondary: #f6f6f6;--color-text-alt: #9fadbd;--color-text-dark: #616161}html{padding:0;margin:0;scroll-behavior:smooth}body{padding:0;margin:0;display:flex;flex-direction:column;min-width:320px;background-color:var(--surface-section)}.body-wrapper{display:flex;flex-direction:column;justify-content:space-between;padding:0;margin:0;width:100%;min-width:320px;min-height:100vh;font-family:\"Work Sans\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",\"Roboto\",\"Helvetica Neue\",Arial,sans-serif}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
