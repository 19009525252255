// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../resources/logo-white.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper[data-v-63657824]{display:flex;flex-direction:column}@media(min-width: 28.71em){.wrapper[data-v-63657824]{align-content:flex-start}}@media(max-width: 28.7em){.wrapper[data-v-63657824]{align-items:center}}.copyright[data-v-63657824]{font-size:.7rem;margin-bottom:.2rem}.logo[data-v-63657824]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:contain;width:10em;height:1.2rem;margin-bottom:.5rem}.social-buttons[data-v-63657824]{display:flex;flex-direction:row;margin-bottom:.5rem;background:none;border:0;color:var(--color-text-primary)}@media(min-width: 28.71em){.social-buttons[data-v-63657824]{justify-content:flex-start}}@media(max-width: 28.7em){.social-buttons[data-v-63657824]{justify-content:center}}.p-menu .p-menuitem-link[data-v-63657824]:not(.p-disabled):hover{background:none}.p-menu .p-menuitem-link[data-v-63657824]{color:var(--color-text-primary);padding:0}.p-menu .p-menuitem-link[data-v-63657824]:not(:first-child){margin-left:15px}.p-menu .p-menuitem-link[data-v-63657824]{color:var(--color-text-primary)}.p-menu .p-menuitem-link .pi[data-v-63657824]{font-size:1.5rem}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
